import { useContext, useEffect, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ImgPrev, LabelProduct } from '@root/components';
import { orderConf } from '@root/conf';
import { useNavigation } from '@root/hooks';
import { WidthContext } from '@root/providers/WidthProvider';
import { theme } from '@root/styles';
import { IconBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const TableHistoryOrder = ({
  data,
  currency,
  checkIsOrderSaved,
  orderHistoryItem,
}) => {
  const { navigation } = useNavigation();
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const chosenCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const currencyName = useSelector(userSelectors.getChoosedCurrency);

  const { screenWidth } = useContext(WidthContext);

  const { color } = useTheme();
  const { t } = useTranslation(['order_history'], { useSuspense: false });

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  useEffect(() => {
    setOpenCard(false);
    setChoseIdCard(null);
  }, [data]);

  const getCurrentProductEntities = (data, key) => {
    return checkIsOrderSaved() ? data.product[key] : data[key];
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ height: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
            {orderConf(t, screenWidth).map(el => {
              return (
                el.isDisplay && (
                  <TableCell
                    key={el.label}
                    sx={{
                      borderBottom: 'none',
                      display: 'flex',
                      ...el.style,
                      p: 0,
                      flexDirection: 'row',
                    }}
                  >
                    <OpacityContainer>
                      <Typography
                        variant={'bodyBoldSmall'}
                        component={'p'}
                        sx={{ color: color.gray_60 }}
                      >
                        {el.label}
                      </Typography>
                    </OpacityContainer>
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
      </Table>
      <Divider />
      <TableBody
        colors={{ gray: color.gray_40, white: color.white, line: color.line }}
      >
        {data &&
          data.map((row, idx) => (
            <>
              <TableRow
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '20px 20px 20px 16px',
                  minHeight: '109px',
                  borderBottom: `1px solid ${color.line}`,
                  th: {
                    borderBottom: 'none',
                  },
                  '&:last-child, &:last-child th': { borderBottom: 0 },
                }}
                key={'pe' + row.id + idx}
              >
                <>
                  {orderConf(t, screenWidth).map((el, i) => {
                    switch (el?.value) {
                      case 'product':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            onClick={() =>
                              navigation(getCurrentProductEntities(row, 'url'))
                            }
                          >
                            <ImgPrev
                              src={getCurrentProductEntities(row, 'image')}
                              countImg={getCurrentProductEntities(
                                row,
                                'images_quantity',
                              )}
                            />
                          </TableCell>
                        );

                      case 'name':
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            key={'hi' + row.id + i}
                            sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            onClick={() =>
                              navigation(getCurrentProductEntities(row, 'url'))
                            }
                          >
                            <LabelProduct
                              label={getCurrentProductEntities(row, 'name')}
                              parm={getCurrentProductEntities(row, 'model')}
                              article={getCurrentProductEntities(row, 'sku')}
                            />

                            {(screenWidth < 769 ||
                              (screenWidth < 1400 && screenWidth > 1024)) && (
                              <>
                                <Typography
                                  variant={'bodySmall'}
                                  component={'p'}
                                  sx={{
                                    color: color.gray_80,
                                    marginTop: '4px',
                                  }}
                                >
                                  {row.quantity} {t('order_history:piece')}
                                </Typography>

                                <ProductInfoMobileWrapper>
                                  <Typography
                                    variant={'bodyBoldMedium'}
                                    component={'p'}
                                    color={theme.color.gray_80}
                                  >
                                    {checkIsOrderSaved() ? (
                                      <>
                                        {row?.amounts_personal[chosenCurrency]}{' '}
                                        {currencyName}
                                      </>
                                    ) : (
                                      <>
                                        {row?.amount} {currency}
                                      </>
                                    )}
                                  </Typography>

                                  <Typography
                                    variant={'bodySmall'}
                                    component={'p'}
                                    sx={{ color: color.gray_80 }}
                                  >
                                    {row?.amount} {currency}
                                    {/*{checkIsOrderSaved() ? (*/}
                                    {/*  <>*/}
                                    {/*    {row?.product?.personal_price[chosenCurrency]}{' '}*/}
                                    {/*    {currencyName}*/}
                                    {/*  </>*/}
                                    {/*) : (*/}
                                    {/*  <>*/}
                                    {/*    {row?.prices[row?.use_price]} {currency}*/}
                                    {/*  </>*/}
                                    {/*)}*/}
                                  </Typography>
                                </ProductInfoMobileWrapper>
                              </>
                            )}
                          </TableCell>
                        );

                      case 'stock':
                        return (
                          el.isDisplay && (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'hi' + row.id + i}
                              sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            >
                              <Typography
                                variant={'bodySmall'}
                                component={'p'}
                                sx={{ color: color.gray_80 }}
                              >
                                {row.quantity} {t('order_history:piece')}
                              </Typography>
                            </TableCell>
                          )
                        );
                      case 'price':
                        return (
                          el.isDisplay && (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'hi' + row.id + i}
                              sx={{
                                p: 0,
                                ...el.style,
                              }}
                            >
                              <Typography
                                variant={'bodySmall'}
                                component={'p'}
                                sx={{ color: color.gray_80 }}
                              >
                                {row?.amount} {currency}
                                {/* {checkIsOrderSaved() ? (
                                <>
                                  {row?.product?.personal_price[chosenCurrency]}{' '}
                                  {currencyName}
                                </>
                              ) : (
                                <>
                                  {row?.prices[row?.use_price]} {currency}
                                </>
                              )} */}
                              </Typography>
                            </TableCell>
                          )
                        );

                      case 'amount':
                        return (
                          el.isDisplay && (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'hi' + row.id + i}
                              sx={{ p: 0, ...el.style }}
                            >
                              <Typography
                                variant={'bodyBoldMedium'}
                                component={'p'}
                                color={theme.color.gray_80}
                              >
                                {checkIsOrderSaved() ? (
                                  <>
                                    {row?.amounts_personal[chosenCurrency]}{' '}
                                    {currencyName}
                                  </>
                                ) : (
                                  <>
                                    {row?.amount} {currency}
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          )
                        );
                      default:
                        return (
                          el.isDisplay && (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'hi' + row.id + i}
                              sx={{ ...el.style }}
                            >
                              <IconBtn
                                href={`${Sprite}#icon-arrow_card`}
                                onClick={() => handelDetailCard(row.id)}
                                size={16}
                                rotateSvg={
                                  openCard && row.id === choseIdCard
                                    ? '180deg'
                                    : '0'
                                }
                              />
                            </TableCell>
                          )
                        );
                    }
                  })}
                </>
              </TableRow>
              {openCard && row.id === choseIdCard && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: '100%', display: 'block' }}
                  >
                    <Typography
                      sx={{}}
                      variant={'bodyBoldSmall'}
                      component={'h3'}
                    >
                      {t('order_history:product_description')}
                    </Typography>
                    <Typography
                      sx={{ mt: '8px' }}
                      variant={'bodySmall'}
                      component={'p'}
                    >
                      {getCurrentProductEntities(row, 'description') && (
                        <EllipsisText
                          text={getCurrentProductEntities(row, 'description')}
                          length={200}
                        />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </>
          ))}
      </TableBody>
    </TableContainer>
  );
};

const TableBody = styled.tbody`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const OpacityContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProductInfoMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;
`;

TableHistoryOrder.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      amount: PropTypes.number,
      description: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      model: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      product_id: PropTypes.number,
      product_name: PropTypes.string,
      product_personal_price: PropTypes.number,
      product_price: PropTypes.number,
      quantity: PropTypes.number,
      sku: PropTypes.string,
      stock: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
  currency: PropTypes.string,
  checkIsOrderSaved: PropTypes.func.isRequired,
};
