import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  Action,
  Availability,
  CardDetails,
  Cost,
  ImgPrev,
  LabelProduct,
  UsePagination,
} from '@root/components';
import ProductLabel from '@root/components/ProductLabel/index';
import { IconBtn, ProgressLoader } from '@root/ui';

import categorySelectors from '@redux/category/category-selectors';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import userSelectors from '@root/redux/user/user-selectors';

export const DenseTable = ({
  conf,
  data,
  meta,
  handelPagination,
  accordionActive,
  choseItem,
  addAnimation,
  handelOpenSavedModal,
  tableBodyHeight = 170,
  handleGetDataBySort,
  loading,
  addOrRemoveFromComparisons,
  isWatchlist,
  handleWatchlistToggle,
  mobileCatalogStyles,
}) => {
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const [sortingOn, setSortingOn] = useState(false);
  const [sortDuration, setSortDuration] = useState(null);
  const [sortingType, setSortingType] = useState(null);
  const warehouseId = useSelector(categorySelectors.getWarehouseId);
  const { category, categoryChildren, lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const userBonus = useSelector(userSelectors.getUserProfile).bonuses;

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();

  const windowWith = useSelector(state => state?.windowWithSlice?.windowWith)

  const handelDetailCard = (e, id) => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };


  const handleSorting = type => {
    switch (type) {
      case 'name': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      case 'price': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      case 'stock': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      default: {
        break;
      }
    }
    setSortingType(type);
    setSortingOn(!sortingOn);
  };

  useEffect(() => {
    setOpenCard(false);
    setChoseIdCard(null);
  }, [meta.current_page]);

  useEffect(() => {
    setSortingType(null);
    setSortDuration(null);
  }, [category, categoryChildren]);

  const getCustomLabels = el => {
    const labels = [];
    const COLORS = {
      top: color.red,
      hit: color.green,
      new: color.main,
      is_sold_with_points: color.background,
      sale: color.orange,
    };
    const prodLabels = el.labels;
    Object.keys(prodLabels).forEach(key => {
      if (prodLabels[key]) {
        labels.push({
          color: COLORS[key],
          text: t(`labels.${key}`),
          // icon: key === 'is_sold_with_points',
          textColor: COLORS[key] === color.background && color.gray_80,
        });
      }
    });
    return labels;
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        height: `calc(100% - ${tableBodyHeight}px)`,
        overflowX: 'initial',
      }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          position: 'relative',
          height: 'calc(100% - 78px)',
        }}
      >
        {windowWith > 768 &&
          <TableHead>
            <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
              {conf.map(el => {
                if (el.hide && accordionActive) return '';
                return (
                  <TableCell
                    key={el.label}
                    sx={{
                      borderBottom: 'none',
                      display: 'flex',
                      ...el.style,
                      p: 0,
                      flexDirection: 'row',
                      maxWidth: ((mobileCatalogStyles && el.value === 'cost') ||
                          (mobileCatalogStyles && el.value === 'availability'))
                          ? 100 : el.style.maxWidth
                    }}
                  >
                    <OpacityContainer opacity={sortingType === el.type ? 1 : 0}>
                      <Typography
                        variant={'bodyBoldSmall'}
                        component={'p'}
                        sx={{ color: color.gray_60 }}
                      >
                        {t(`common:table.table_heads.${el.value}`)}
                      </Typography>
                      {el.sort && (
                        <IconBtn
                          href={`${Sprite}#icon-sorting`}
                          onClick={() => handleSorting(el.type)}
                          size={16}
                          sx={{
                            marginLeft: '6px',
                          }}
                          rotateSvgX={
                            sortDuration === el.type ? '3.142rad' : false
                          }
                        />
                      )}
                    </OpacityContainer>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        }
        <Divider />
        <TableBody
          colors={{ gray: color.gray_40, white: color.white, line: color.line }}
        >
          {!mobileCatalogStyles && data &&
            data.map((row, idx) => (
              <>
                <TableRow
                  sx={{
                    // minHeight: '109px',
                    borderBottom: `1px solid ${color.line}`,
                    userSelect: 'auto',
                    th: {
                      borderBottom: 'none',
                    },
                    '&:last-child, &:last-child th': { borderBottom: 0 },
                  }}
                  key={'pe' + row.id + idx}
                >
                  <TableRowInner hasLabels={getCustomLabels(row).length}>
                    <ProductLabel
                      customLabels={getCustomLabels(row)}
                      itemSx={{
                        fontSize: '10px',
                        marginRight: '4px',
                        whiteSpace: 'nowrap',
                      }}
                      sx={{ position: 'absolute', top: '7px' }}
                    />
                    {conf.map((el, i) => {
                      switch (el?.value) {
                        case 'product':
                          return (
                            <NavLink
                              to={currentLang + row.url}
                              style={{ ...el.style }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                key={'si' + row.id + i}
                                sx={{
                                  p: 0,
                                  ...el.style,
                                  cursor: 'pointer',
                                  position: 'relative',
                                }}
                              >
                                <ImgPrev
                                  src={row?.image}
                                  countImg={row?.images_quantity}
                                />
                              </TableCell>
                            </NavLink>
                          );

                        case 'name':
                          return (
                            <NavLink
                              to={currentLang + row.url}
                              style={{ ...el.style }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                key={'si' + row.id + i}
                                sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                              >
                                <LabelProduct
                                  label={row?.name}
                                  parm={row?.model}
                                  article={row?.sku}
                                  hasGuid={row.has_guid}
                                />
                              </TableCell>
                            </NavLink>
                          );

                        case 'cost':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'si' + row.id + i}
                              sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                            >
                              <Cost data={row} isHiddenTooltip={userBonus.status === 'blocked'} />
                            </TableCell>
                          );
                        case 'availability':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'si' + row.id + i}
                              sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px',
                                cursor: 'pointer',
                                ...el.style,
                              }}
                            >
                              <Availability
                                present={row.stock_percent}
                                inStock={row.stock_max}
                                notAvailable={row.stock}
                                storage={row.stock} //зачем?
                                warehouseId={warehouseId}
                                warehouses={row.warehouses}
                              />
                            </TableCell>
                          );

                        case 'action':
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'si' + row.id + i}
                              sx={{ p: 0, ...el.style }}
                            >
                              <Action
                                addAnimation={addAnimation}
                                chooseProduct={row.cart}
                                isSaved={row.is_saved}
                                inCompare={row.in_compare}
                                availableNumber={row.stock}
                                handelOpenSavedModal={e =>
                                  handelOpenSavedModal(e, row.is_saved, row.id)
                                }
                                handelDetailCard={e =>
                                  handelDetailCard(e, row.id)
                                }
                                isOpenDetail={
                                  openCard && choseIdCard === row.id
                                }
                                productPrice={row.price}
                                addedToCart={row.is_in_cart}
                                onClick={e => choseItem(e, row)}
                                data={row}
                                addOrRemoveFromComparisons={() =>
                                  addOrRemoveFromComparisons(
                                    row.id,
                                    row.in_compare,
                                  )
                                }
                                isWatchlist={isWatchlist}
                                handleWatchlistToggle={handleWatchlistToggle}
                              />
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={'si' + row.id + i}
                            >
                              {row[el.value]}
                            </TableCell>
                          );
                      }
                    })}
                  </TableRowInner>
                </TableRow>
                {openCard && row.id === choseIdCard && (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ p: 0, width: '100%', display: 'block' }}
                    >
                      <CardDetails
                        handelOpenSavedModal={handelOpenSavedModal}
                        addOrRemoveFromComparisons={addOrRemoveFromComparisons}
                        id={choseIdCard}
                        t={t}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}

            {/* MOBILE VERSION */}
            {mobileCatalogStyles && data &&
                data.map((row, idx) => (
                    <>
                        <TableRow
                            sx={{
                                // minHeight: '109px',
                                borderBottom: `1px solid ${color.line}`,
                                userSelect: 'auto',
                                th: {
                                    borderBottom: 'none',
                                },
                                '&:last-child, &:last-child th': { borderBottom: 0 },
                            }}
                            key={'pe' + row.id + idx}
                        >

                            <TableRowInner hasLabels={getCustomLabels(row).length}>
                              <ProductLabel
                                  customLabels={getCustomLabels(row)}
                                  itemSx={{
                                      fontSize: '10px',
                                      marginRight: '4px',
                                      whiteSpace: 'nowrap',
                                  }}
                                  sx={{ position: 'absolute', top: '7px' }}
                              />
                              {conf.map((el, i) => {
                                  switch (el?.value) {
                                      case 'product':
                                          return (
                                              <NavLink
                                                  to={currentLang + row.url}
                                                  style={{ ...el.style }}
                                              >
                                                  <TableCell
                                                      component="th"
                                                      scope="row"
                                                      key={'si' + row.id + i}
                                                      sx={{
                                                          p: 0,
                                                          ...el.style,
                                                          cursor: 'pointer',
                                                          position: 'relative',
                                                      }}
                                                  >
                                                      <ImgPrev
                                                          src={row?.image}
                                                          countImg={row?.images_quantity}
                                                      />
                                                  </TableCell>
                                              </NavLink>
                                          );

                                      case 'name':
                                          return (
                                              <NavLink
                                                  to={currentLang + row.url}
                                                  style={{ ...el.style, marginLeft: 0 }}
                                              >
                                                  <TableCell
                                                      component="th"
                                                      scope="row"
                                                      key={'si' + row.id + i}
                                                      sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                                                  >
                                                      <LabelProduct
                                                          label={row?.name}
                                                          parm={row?.model}
                                                          article={row?.sku}
                                                          hasGuid={row.has_guid}

                                                          isEllipsis={true}
                                                          lengthEllipsis = {
                                                                {
                                                                    label: windowWith > 768 ? 30 : 20,
                                                                    parm: windowWith > 768 ? 50 : 20,
                                                                    article: windowWith > 768 ? 50 : 20,
                                                                }
                                                            }
                                                          mobileCatalogStyles={mobileCatalogStyles}
                                                      />
                                                  </TableCell>
                                              </NavLink>
                                          );

                                      case 'cost':
                                          return (
                                              <TableCell
                                                  component="th"
                                                  scope="row"
                                                  key={'si' + row.id + i}
                                                  sx={
                                                    {
                                                        p: 0,
                                                        ...el.style,
                                                        cursor: 'pointer',
                                                        // maxWidth: windowWith > 768 ? 100 : 90
                                                        maxWidth: 100
                                                      }
                                                    }
                                              >
                                                  <Cost
                                                      data={row}
                                                      mobileCatalogStyles={mobileCatalogStyles}
                                                  />
                                              </TableCell>
                                          );

                                      case 'availability':
                                          return (
                                            <>
                                                {
                                                  windowWith > 768 &&
                                                  <TableCell
                                                      component="th"
                                                      scope="row"
                                                      key={'si' + row.id + i}
                                                      sx={{
                                                          p: 0,
                                                          display: 'flex',
                                                          flexDirection: 'column',
                                                          gap: '4px',
                                                          cursor: 'pointer',
                                                          ...el.style,
                                                          maxWidth: 80
                                                      }}
                                                  >
                                                      <Availability
                                                          present={row.stock_percent}
                                                          inStock={row.stock_max}
                                                          notAvailable={row.stock}
                                                          storage={row.stock}
                                                          warehouseId={warehouseId}
                                                          warehouses={row.warehouses}
                                                      />
                                                  </TableCell>
                                                }
                                            </>
                                          );

                                      case 'action':
                                          return (
                                              <TableCell
                                                  component="th"
                                                  scope="row"
                                                  key={'si' + row.id + i}
                                                  sx={
                                                    {
                                                      p: 0,
                                                        ...el.style,
                                                        maxWidth: windowWith <= 768 ? 72 : el.style.maxWidth
                                                    }
                                                }
                                              >
                                                  <Action
                                                      addAnimation={addAnimation}
                                                      chooseProduct={row.cart}
                                                      isSaved={row.is_saved}
                                                      inCompare={row.in_compare}
                                                      availableNumber={row.stock}
                                                      handelOpenSavedModal={e =>
                                                          handelOpenSavedModal(e, row.is_saved, row.id)
                                                      }
                                                      handelDetailCard={e =>
                                                          handelDetailCard(e, row.id)
                                                      }
                                                      isOpenDetail={
                                                          openCard && choseIdCard === row.id
                                                      }
                                                      productPrice={row.price}
                                                      addedToCart={row.is_in_cart}
                                                      onClick={e => choseItem(e, row)}
                                                      data={row}
                                                      addOrRemoveFromComparisons={() =>
                                                          addOrRemoveFromComparisons(
                                                              row.id,
                                                              row.in_compare,
                                                          )
                                                      }
                                                      isWatchlist={isWatchlist}
                                                      handleWatchlistToggle={handleWatchlistToggle}
                                                      isHideArrowCard={true}
                                                      showControllers={windowWith <= 768 ? false : true}
                                                      mobileCatalogStyles={mobileCatalogStyles}
                                                  />
                                              </TableCell>
                                          );

                                      default:
                                          return (
                                              <TableCell
                                                  component="th"
                                                  scope="row"
                                                  key={'si' + row.id + i}
                                              >
                                                  {row[el.value]}
                                              </TableCell>
                                          );
                                  }
                              })}
                          </TableRowInner>
                        </TableRow>
                        {/*{openCard && row.id === choseIdCard && (*/}
                        {/*    <TableRow>*/}
                        {/*        <TableCell*/}
                        {/*            component="th"*/}
                        {/*            scope="row"*/}
                        {/*            sx={{ p: 0, width: '100%', display: 'block' }}*/}
                        {/*        >*/}
                        {/*            <CardDetails*/}
                        {/*                handelOpenSavedModal={handelOpenSavedModal}*/}
                        {/*                addOrRemoveFromComparisons={addOrRemoveFromComparisons}*/}
                        {/*                id={choseIdCard}*/}
                        {/*                t={t}*/}
                        {/*            />*/}
                        {/*        </TableCell>*/}
                        {/*    </TableRow>*/}
                        {/*)}*/}
                    </>
                ))}

          {loading && (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ p: 0, width: '100%', display: 'block' }}
              >
                <ProgressLoader />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <UsePagination meta={meta} handelPagination={handelPagination} />
    </TableContainer>
  );
};

const TableRowInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ hasLabels }) => (hasLabels ? '30px' : '20px')} 20px 20px 16px;

  @media(max-width: 768px) {
    padding: ${({ hasLabels }) => (hasLabels ? '30px' : '20px')} 5px 15px 5px;
  }
`;

const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;

  height: calc(100% - 40px);

  overflow-y: overlay;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.colors.gray};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.colors.white};
    border-left: 1px solid ${props => props.colors.line};
  }

  @media(max-width: 768px) {
    height: 100%;
  }

  @media(max-width: 425px) {
    overflow-x: hidden;
  }
`;

const OpacityContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: ${({ opacity }) => opacity};
  }
`;

DenseTable.propTypes = {
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      style: PropTypes.object,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(
    PropTypes.exact({
      brand: PropTypes.string,
      can_buy: PropTypes.exact({
        price: PropTypes.bool,
        price_uah_cash: PropTypes.bool,
        price_uah_no_pdv: PropTypes.bool,
        price_uah_pdv: PropTypes.bool,
      }),
      cart: PropTypes.exact({
        cart_product_id: PropTypes.number,
        id: PropTypes.number,
        personal_amount: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        quantity: PropTypes.number,
      }),
      description: PropTypes.string,
      has_analogs: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      images_quantity: PropTypes.number,
      in_compare: PropTypes.bool,
      is_in_cart: PropTypes.bool,
      is_saved: PropTypes.bool,
      model: PropTypes.string,
      name: PropTypes.string,
      personal_price: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      price: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      prices: PropTypes.exact({
        price: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_cash: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_no_pdv: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_pdv: PropTypes.exact({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
      sku: PropTypes.string,
      slug: PropTypes.string,
      stock: PropTypes.number,
      stock_max: PropTypes.number,
      stock_percent: PropTypes.number,
      url: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.exact({
          city_name: PropTypes.string,
          warehouses: PropTypes.exact({
            name: PropTypes.string,
            stock: PropTypes.number,
            stock_max: PropTypes.number,
            stock_percent: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  meta: PropTypes.exact({
    current_page: PropTypes.number,
    from: PropTypes.number,
    last_page: PropTypes.number,
    links: PropTypes.arrayOf(
      PropTypes.exact({
        url: PropTypes.string,
        label: PropTypes.string,
        active: PropTypes.bool,
      }),
    ),
    path: PropTypes.string,
    per_page: PropTypes.number,
    to: PropTypes.number,
    total: PropTypes.number,
  }),
  handelPagination: PropTypes.func.isRequired,
  accordionActive: PropTypes.bool,
  choseItem: PropTypes.func.isRequired,
  addAnimation: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  tableBodyHeight: PropTypes.number,
  handleGetDataBySort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
