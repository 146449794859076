import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty } from '@root/components';
import IconCheck from '@root/components/IconCheck/index';
import { ProfileConf } from '@root/conf';
import { AvatarUI } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ProfileSettingsList = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['profile'], { useSuspense: false });
  const { profileTab } = useParams();
  const userPermissions = useSelector(userSelectors.getPermissions);
  const hasGuid = useSelector(userSelectors.getUserHasGuid);

  const userProfile = useSelector(userSelectors.getUserProfile);

  return (
    <ProfileSettingsListWrapper
      colors={{ white: color.white, line: color.line }}
    >
      <ProfileSettingsListIntro>
        <ProfileSettingsListLogo>
          {userProfile.avatar ? (
            <Img src={userProfile.avatar} />
          ) : (
            <AvatarUI width={60} height={60}>
              <Empty
                type={'EmptyLogo'}
                isCompany={false}
                name={userProfile.userName}
                noAvatarSx={{
                  fontSize: '1.375rem',
                }}
              />
            </AvatarUI>
          )}
        </ProfileSettingsListLogo>
        <ProfileSettingsListInfo>
          <Typography color={color.gray} variant="h2">
            {userProfile.userName} <IconCheck display={hasGuid} height={20} />
          </Typography>
          <Typography
            color={color.gray_60}
            variant="bodyMedium"
            component="p"
            marginTop="9px"
          >
            {userProfile.userEmail}
          </Typography>
        </ProfileSettingsListInfo>
      </ProfileSettingsListIntro>

      {ProfileConf(t)
        .filter(item =>
          item.value === 'roles' ? userPermissions[item.permission_key] : true,
        )
        .map(profile => (
          <NavLink to={`/profile/${profile.path}`}>
            <ProfileSettingLink
              isActive={profileTab === profile.path}
              colors={{
                line: color.line_light,
                main: color.main,
                gray: color.gray_60,
              }}
            >
              <Svg>
                <use href={`${Sprite}#${profile.icon}`} />
              </Svg>
              <Typography
                color={
                  profileTab === profile.path
                    ? `${color.main} !important`
                    : color.gray_80
                }
                variant="bodyMedium"
                sx={{ flex: '1 1 auto' }}
              >
                {profile.label}
              </Typography>

              <Svg>
                <use href={`${Sprite}#icon-arrow-right`} />
              </Svg>
            </ProfileSettingLink>
          </NavLink>
        ))}
    </ProfileSettingsListWrapper>
  );
};

const ProfileSettingsListWrapper = styled.div`
  flex: 0 0 30%;
  background: ${props => props.colors.white};
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  height: calc(100vh - 170px);

  @media (max-width: 1024px) {
    flex: 0 0 100%;
  }
`;

const ProfileSettingsListIntro = styled.div`
  display: flex;
  column-gap: 18px;
  padding: 30px;
  align-items: center;
`;
const ProfileSettingsListLogo = styled.div``;
const ProfileSettingsListInfo = styled.div`
  display: block;
  overflow: hidden;
  word-break: break-all;
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
`;

const ProfileSettingLink = styled.div`
  padding: 12px 30px;
  border-top: 1px solid ${props => props.colors.line};
  display: flex;
  align-items: center;
  column-gap: 9px;
  background: ${({ isActive, colors }) =>
    isActive
      ? `linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), ${colors.main};`
      : ''};
  fill: ${({ isActive, colors }) => (isActive ? colors.main : colors.gray)};
  stroke: ${({ isActive, colors }) => (isActive ? colors.main : colors.gray)};

  @media (max-width: 768px) {
    padding: 10px 16px;
  }
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
`;
