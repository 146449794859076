import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import PropTypes, { number } from 'prop-types';
import styled from 'styled-components';

import { EditModeInput, InputBtnConfirm } from '@root/components';
import IconCheck from '@root/components/IconCheck/index';
import { categoryList } from '@root/conf';
import { handleDispatchFetch } from '@root/helpers/handleDispatchFetch';
import { setOpenNotification } from '@root/redux/notification-watcher/notification-watcher-slice';
import productOperation from '@root/redux/product/product-operation';
import { theme } from '@root/styles';

import categorySelectors from '@redux/category/category-selectors';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';
import productSelectors from '@redux/product/product-selectors';

import { Box, MenuItem, Typography, useTheme } from '@mui/material';

export const ProductTitle = ({
  isCreate,
  data,
  isEdit,
  editData,
  handleLangData,
  lang,
  error,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['product_details'], {
    useSuspense: false,
  });
  const brands = useSelector(optionSelectors.getBrands);
  const category = useSelector(categorySelectors.getCategoryListToEdit);
  const loadingEdit = useSelector(productSelectors.getLoadingEditPage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(optionOperation.getBrands({}));
  }, []);

  const onSync1c = () => {
    handleDispatchFetch(
      ({ onReject, onResolve }) =>
        dispatch(
          productOperation.syncProductWith1C({
            productId: data.id,
            onReject,
            onResolve,
          }),
        ),
      () =>
        dispatch(
          setOpenNotification({
            open: true,
            message: t('product_details:sync_success'),
          }),
        ),
      () => {},
    );
  };

  return (
    <NameWrapper>
      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="input"
        conf={{
          onChange: handleLangData,
          value: editData?.name[lang],
          error: error,
          key: 'name',
          placeholder: t('product_details:placeholder.name_of_product'),
        }}
      >
        <Typography
          variant={'h1'}
          sx={{
            color: color.gray,
          }}
        >
          {data.name} <IconCheck display={data.has_guid} height={28} />
        </Typography>
      </EditModeInput>
      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="input"
        conf={{
          onChange: handleLangData,
          value: editData?.model,
          error: error,
          key: 'model',
          placeholder: t('product_details:placeholder.model_of_product'),
        }}
      >
        {' '}
        <Typography
          variant={'h3'}
          sx={{
            color: color.gray_80,
          }}
        >
          {data.model}
        </Typography>
      </EditModeInput>

      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="select"
        conf={{
          onChange: handleLangData,
          value: editData?.brand_id,
          error: error,
          key: 'brand_id',
          placeholder: t('product_details:placeholder.brand_of_product'),
          options: brands,
          group: false,
          customItem: (
            <MenuItem
              sx={{
                backgroundColor: `${color.white} !important`,
                color: `${color.white} !important`,
                '&:hover': {
                  backgroundColor: `${color.white} !important`,
                  color: `${color.white} !important`,
                },
                '&:active': {
                  backgroundColor: `${color.white}!important`,
                  color: `${color.white}!important`,
                },
              }}
            >
              <InputBtnConfirm
                handleConfirm={name => {
                  document
                    .getElementsByClassName('MuiBackdrop-invisible')[0]
                    .click();
                  dispatch(optionOperation.addBrands({ name }));
                }}
                placeholder={t('product_details:placeholder.add_new_brand')}
              />
            </MenuItem>
          ),
        }}
      >
        <Typography
          variant={'h3'}
          sx={{
            color: color.gray_80,
          }}
        >
          {data?.brand}
        </Typography>
      </EditModeInput>

      <SkuWrp>
        {isEdit && (
          <Typography
            variant={'bodyMedium'}
            sx={{
              color: color.gray_60,
              marginRight: '8px',
            }}
          >
            {t('product_details:product')}:
          </Typography>
        )}

        {isEdit ? (
          <EditModeInput
            isLoading={loadingEdit}
            isEdit={isEdit}
            type="input"
            conf={{
              onChange: handleLangData,
              value: editData?.sku,
              error: error,
              key: 'sku',
              // placeholder: t('product_details:placeholder.name_of_product'),
            }}
            sx={{
              maxWidth: !isCreate && isEdit ? '211px' : '355px',
            }}
            inputSx={{
              width: !isCreate && isEdit ? '211px' : '355px',
            }}
          >
            <SkuFieldWrp>
              <Typography
                variant={'bodyMedium'}
                sx={{
                  color: color.gray_60,
                }}
              >
                {data.sku}
              </Typography>
            </SkuFieldWrp>
          </EditModeInput>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_60,
              }}
            >
              {t('product_details:product')}: {data.sku}
            </Typography>
            {data.sync_date && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Typography variant="bodyBoldSmall" color={color.green}>
                  <IconCheck
                    display={true}
                    height={16}
                    sx={{ marginRight: '8px' }}
                  />{' '}
                  {t('product_details:syncn')}
                </Typography>
                <Typography variant="bodyExSmall" color={color.gray_80}>
                  {moment(data.sync_date).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {!isCreate && isEdit && (
          <SyncBtn onClick={onSync1c}>
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray_60,
              }}
            >
              {t('product_details:sync_btn')}
            </Typography>
          </SyncBtn>
        )}
      </SkuWrp>

      {/* <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="select"
        conf={{
          onChange: handleLangData,
          value: editData?.category_id,
          error: {},
          key: 'category_id',
          placeholder: t(
            'product_details:placeholder.main_category_of_product',
          ),
          options: categoryList(category),
          group: true,
        }}
      />

      <EditModeInput
        isLoading={loadingEdit}
        isEdit={isEdit}
        type="multipleSelect"
        conf={{
          onChange: handleLangData,
          value: editData?.categories,
          error: {},
          key: 'categories',
          placeholder: t(
            'product_details:placeholder.other_category_of_product',
          ),
          options: categoryList(category),
        }}
      /> */}
    </NameWrapper>
  );
};

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

const SkuWrp = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SkuFieldWrp = styled.div(() => ({
  height: '36px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 14px',
  width: '180px',
  border: `1px solid ${theme.color.line}`,
}));

const SyncBtn = styled.div(() => ({
  marginLeft: '16px',
  height: '36px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '128px',
  cursor: 'pointer',
  border: `1px solid ${theme.color.line}`,
}));

ProductTitle.propTypes = {
  data: PropTypes.exact({
    analogue_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    documents: PropTypes.array,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        big: PropTypes.string,
        full: PropTypes.string,
        thumbnail: PropTypes.string,
        thumbnail_second: PropTypes.string,
      }),
    ),
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    property_categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            is_pinned: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    related_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        relation_quantity: PropTypes.number,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
  }),
  isEdit: PropTypes.bool.isRequired,
  editData: PropTypes.exact({
    brand_id: PropTypes.number,
    categories: PropTypes.arrayOf(number),
    category_id: PropTypes.number,
    id: PropTypes.number,
    model: PropTypes.string,
    name: PropTypes.exact({ uk: PropTypes.string, en: PropTypes.string }),
  }),
  handleLangData: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
